import React, { useState } from "react"
import { Formik, Form } from "formik"
import axios from "axios"
import * as Yup from "yup"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import { menuItems } from "../services/constants"
import { ThankYou, TextInput, PhoneInput, TextArea } from "../components/forms"
import "../styles.css"

export default props => {
  const [isSubmitted, setIsSubmitted] = useState(false)

  return (
    <Layout
      isServiceLayout={true}
      bgColorClass='bg-gray-800'
      menuItems={menuItems}
      helmetTitle="Contact Us | Website Design, Copywriting, Photography, Logo Design | Red Brick Road Studio"
      style={{
        backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7)),
        url('https://res.cloudinary.com/frannsoft/image/upload/f_auto,q_auto,fl_lossy,w_1800/v1530411198/redbrickroadstudio/homebackground_op.jpg')`,
        backgroundSize: `cover`,
        backgroundPosition: `center`,
        backgroundRepeat: `no-repeat`
      }}
      contentInsideBackground={() => (
        <div className='xs:max-w-sm sm:max-w-xl m-4 mx-auto sm:my-8 h-screen-9/10'>
          <div
            className='bg-white shadow-md rounded px-8 sm:px-12 pt-6 pb-8 mb-4 mx-4 text-gray-800'
            style={{ boxSizing: `border-box` }}>
            <div>
              <div className='mb-4'>
                <h1 className={`text-3xl my-4 ${isSubmitted ? "text-center" : ""}`}>
                  {isSubmitted ? "Thank you!" : "Get in touch"}
                </h1>
                {isSubmitted ? null : (
                  <div className='mb-4'>
                    <p>We will get back to you shortly!</p>
                  </div>
                )}
                <hr />
              </div>
              {isSubmitted ? (
                <ThankYou />
              ) : (
                <Formik
                  initialValues={{
                    firstName: "",
                    lastName: "",
                    email: "",
                    phoneNumber: "",
                    comments: ""
                  }}
                  validationSchema={Yup.object({
                    firstName: Yup.string()
                      .max(15, "Must be 15 characters or less")
                      .required("Please fill out this field."),
                    lastName: Yup.string()
                      .max(20, "Must be 20 characters or less")
                      .required("Please fill out this field."),
                    email: Yup.string()
                      .email("Invalid email address")
                      .required("Please fill out this field."),
                    phoneNumber: Yup.string()
                      .matches(
                        /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
                        "Please enter valid phone number"
                      )
                      .required("Please fill out this field."),
                    comments: Yup.string().required("Please fill out this field."),
                    _gotcha: Yup.string().matches("")
                  })}
                  onSubmit={async (values, { setSubmitting }) => {
                    await axios.post("https://usebasin.com/f/a4e1f52e7b2c", values, {
                      headers: { Accept: "application/json" }
                    })
                    setSubmitting(false)
                    setIsSubmitted(true)
                  }}>
                  {({ errors, touched, handleSubmit }) => {
                    const hasErrors = Object.keys(errors).length > 0
                    return (
                      <Form>
                        <div>
                          <div className='font-semibold mb-4'>
                            <span>Your Basic Information</span>
                          </div>
                          <div className='flex flex-col sm:flex-row justify-between'>
                            <div>
                              <TextInput
                                required
                                label='First Name'
                                name='firstName'
                                type='text'
                                placeholder='Jane'
                              />
                            </div>
                            <div>
                              <TextInput
                                required
                                label='Last Name'
                                name='lastName'
                                type='text'
                                placeholder='Doe'
                              />
                            </div>
                          </div>
                        </div>
                        <TextInput
                          required
                          label='Email'
                          name='email'
                          type='email'
                          placeholder='jane@example.com'
                          className='shadow appearance-none border rounded max-w-xs w-full py-2 px-3 text-gray-700 mb-6 leading-tight focus:outline-none focus:shadow-outline bg-gray-200'
                        />
                        <TextInput
                          label='Company'
                          name='company'
                          type='text'
                          placeholder='Fantastic Company, Inc.'
                        />
                        <PhoneInput required type='tel' label='Phone Number' name='phoneNumber' />
                        <hr />
                        <div className='font-semibold my-4'>
                          <TextArea
                            required
                            label='Comments'
                            name='comments'
                            placeholder='Tell us about your project, needs and timeline.'
                          />
                        </div>
                        <input type='hidden' name='_gotcha' />
                        <div className='text-gray-700 text-xs sm:text-sm mb-4'>
                          <span>
                            A copy of your inquiry will be emailed to the email address used above.
                          </span>
                        </div>
                        <div className='w-full sm:flex sm:justify-end'>
                          <button
                            disabled={hasErrors}
                            readOnly={hasErrors}
                            type='submit'
                            className={`w-full sm:w-1/3 font-semibold bg-primary border-primary text-md border-4 text-white py-1 px-2 rounded ${
                              hasErrors
                                ? "opacity-75 cursor-auto"
                                : "hover:bg-hover hover:border-hover"
                            }`}
                            onClick={!hasErrors ? e => handleSubmit(e) : null}>
                            Submit
                          </button>
                        </div>
                      </Form>
                    )
                  }}
                </Formik>
              )}
            </div>
          </div>
        </div>
      )}></Layout>
  )
}

export const backgroundQuery = graphql`
  query backgroundContactUs {
    file(name: { regex: "/(andrej-lisakov)/" }) {
      childImageSharp {
        gatsbyImageData(width: 1300)
      }
    }
  }
`
