import React from "react"
import { Field, useField } from "formik"
import { phoneNumberMask } from "../../services/constants"
import MaskedInput from "react-text-mask"

export const PhoneInput = ({ label, ...props }) => {
  const [field, meta] = useField(props)
  return (
    <Field name={props.name}>
      {() => {
        return (
          <div className='mb-4'>
            <label htmlFor={props.id || props.name} className='font-bold mb-2 block text-sm'>
              {label}
              {props.required ? "" : ""}
            </label>
            <MaskedInput
              {...field}
              mask={phoneNumberMask}
              name={props.name}
              placeholder='Enter your phone number'
              type='text'
              className={`placeholder-gray-600 shadow appearance-none border rounded w-full sm:max-w-xs py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-200 ${
                (meta.touched && meta.error) ||
                (props.meta && props.meta.touched && props.meta.error)
                  ? "border-red-500"
                  : ""
              }`}
            />
            {meta.touched && meta.error ? (
              <div className='text-xs mt-1 text-red-500 italic'>{meta.error}</div>
            ) : null}
          </div>
        )
      }}
    </Field>
  )
}
