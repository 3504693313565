import React from "react"
import { Link } from 'gatsby'

export const ThankYou = props => {
  return (
    <div className='text-center text-gray-800 flex flex-col items-center'>
      <p className='mt-4'>We will get back to you shortly!</p>
      <div className='xs:w-1/3 sm:w-full font-semibold hover:bg-hover hover:border-hover bg-primary border-primary text-md border-4 text-white py-1 px-2 mt-12 rounded'>
        <Link to='/'>Go to Home page</Link>
      </div>
    </div>
  )
}
