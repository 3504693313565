import React from "react"
import { useField } from "formik"

export const TextInput = ({ label, ...props }) => {
  const [field, meta] = useField(props)

  return (
    <div className='mb-4'>
      <label htmlFor={props.id || props.name} className='font-bold mb-2 block text-sm'>
        {label}
        <span className='text-gray-700'>{props.required ? "" : " (Optional)"}</span>
      </label>
      <input
        {...field}
        {...props}
        className={`placeholder-gray-600 shadow appearance-none border rounded max-w-xs w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-200 ${
          meta.touched && meta.error ? "border-red-500" : ""
        }`}
      />
      {meta.touched && meta.error ? (
        <div className='text-xs mt-1 text-red-500 italic'>{meta.error}</div>
      ) : null}
    </div>
  )
}
